import React from 'react'
import ContainerDimensions from 'react-container-dimensions'
import { keys as breakpointKeys } from '@material-ui/core/styles/createBreakpoints'
import { withTheme, WithTheme } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core/styles/createMuiTheme'

interface iGetWidth {
  theme: Theme
  innerWidth: number
}
export const getWidth = ({ theme, innerWidth }: iGetWidth) => {
  const breakpoints = theme.breakpoints
  let width = null
  /**
   * Start with the slowest value as low end devices often have a small screen.
   *
   * innerWidth |xs      sm      md      lg      xl
   *            |-------|-------|-------|-------|------>
   * width      |  xs   |  sm   |  md   |  lg   |  xl
   */
  let index = 1
  while (width === null && index < breakpointKeys.length) {
    const currentWidth = breakpointKeys[index]
    // @media are inclusive, so reproduce the behavior here.
    if (innerWidth < breakpoints.values[currentWidth]) {
      width = breakpointKeys[index - 1]
      break
    }
    index += 1
  }
  width = width || 'xl'
  return width
}

export interface WithWidth {
  width: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  widthPx: number
}

function withWidth(Component: any) {
  return withTheme(({ theme, ...props }: WithTheme) => (
    <ContainerDimensions>
      {({ width }) => (
        <Component
          widthPx={width}
          width={getWidth({ theme: theme, innerWidth: width })}
          {...props}
        />
      )}
    </ContainerDimensions>
  ))
}

export default withWidth
